@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

html {
  @apply !scroll-smooth;
}

body {
  @apply flex min-h-screen flex-col overflow-x-hidden text-gray-800 antialiased;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

main {
  @apply pt-20;
}

section {
  scroll-margin-top: 80px;
}

a,
input,
button {
  @apply outline-none;
}

a,
button,
[role='button'] {
  -webkit-tap-highlight-color: transparent;
}

@layer utilities {
  .fill-path-white {
    @apply [&>path]:fill-white;
  }

  .fill-path-gray {
    @apply [&>path]:fill-gray-400;
  }

  .float-link {
    @apply absolute inset-0 z-20 flex h-full w-full;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@layer components {
  .container-fluid {
    @apply mx-auto w-full max-w-screen-3xl px-6 lg:px-12 xl:px-20 2xl:px-32;
  }

  .card-gradient {
    @apply relative border border-gray-100 transition-colors duration-300 focus-within:outline-dotted focus-within:outline-offset-4 focus-within:outline-gray-400/30 hover:border-green-400 hover:after:opacity-100;

    &:before,
    &:after {
      @apply pointer-events-none absolute inset-0 h-full w-full select-none transition-opacity duration-300;
    }

    &:before {
      @apply z-0 bg-white;
    }

    &:after {
      @apply z-10 bg-gradient-to-b from-green-50/75 to-lemon-50/0 opacity-0;
    }

    &[aria-checked='true'] {
      @apply border-green-400;
    }

    &[aria-checked='true']:after {
      @apply opacity-100;
    }
  }
}
